<template>
  <div class="success-modal" ref="success">
    <span class="material-icons" @click="closeModal"> close </span>
    <img src="../assets/correct.svg" alt="" />
    <h2>{{$t('homeModalToastSuccess.titleSuccess')}}</h2>
    <span class="text">
      <span>{{$t('homeModalToastSuccess.thanksMessage')}}</span> <br />

      <span
        >{{$t('homeModalToastSuccess.toastInfo')}}</span
      ><br />

      <span>{{$t('homeModalToastSuccess.quickResponseInfo')}} <br/>{{$t('homeModalToastSuccess.messageReminder')}}<br/>xiaomi.mkd.prijava@gmail.com</span>
      </span>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    mounted() {
      this.$refs.success.scrollIntoView();
    },
  },
};
</script>

<style scoped lang='scss'>
.success-modal {
  padding: 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 999;
  position: absolute;
  min-height: 220px;
  padding: 40px 0;
  width: 400px;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  h2 {
    text-align: center;
  }
  .text {
    margin: 20px 0;
    color: rgb(55, 226, 120);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    span {
      display: inline-block;
      margin: 5px 0;
    }
    .email {
      color: rgb(43, 43, 43);
    }
  }
  .material-icons {
    position: absolute;
    left: calc(100% - 30px);
    top: 20px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 450px) {
  .success-modal {
    width: 100%;
  }
}
</style>