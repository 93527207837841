<template>
  <div class="home-modal-component" ref="modal">
    <span class="material-icons" @click="closeModal">
      close
    </span>
    <h3>{{ $t('modal.currentGift') }}</h3>
    <div class="flex-wrap">
      <div
        class="flex-wrap-left"
        :class="{ selected: selected }"
        @click="selected = true"
      >
        <img class="gift-preview" :src="giftImg" alt="" />
        <!-- <span v-if="!gift.gift_text_under_image">{{ gift.gift_name }}</span> -->
      </div>
    </div>
    <div class="selected-item-notification"></div>
    <h3>{{ $t('modal.essentialInfo') }}</h3>
    <InputWrapComponent
      :spanContent="$t('modal.name')"
      value="name"
      @inputValue="handleInputValue"
    />
    <InputWrapComponent
      :spanContent="$t('modal.lastname')"
      value="lastname"
      @inputValue="handleInputValue"
    />
    <InputWrapComponent
      :spanContent="$t('modal.email')"
      value="email"
      @inputValue="handleInputValue"
    />
    <div class="select-wrap">
      <span>{{ $t('modal.retailer') }}</span>
      <select v-model="ret_id">
        <option value="" selected disabled hidden>
          {{ $t('modal.retailerPlaceholder') }}
        </option>
        <option v-for="el in retailers" :key="el.ret_id" :value="el.ret_id">
          {{ el.ret_name }}
        </option>
      </select>
    </div>
    <div class="select-wrap">
      <span>{{ $t('modal.chosenSmartphone') }}</span>
      <select>
        <option
          v-if="currentModelsLength"
          :value="selectedModelId"
          selected
          disabled
          hidden
        >
          {{
            currentModelsLength
              ? currentModels[0].pro_name
              : $t('modal.chosenSmartphonePlaceholder')
          }}
        </option>
        <option v-for="el in currentModels" :key="el.pro_id" :value="el.pro_id">
          {{ el.pro_name }}
        </option>
      </select>
    </div>
    <InputWrapComponent
      :spanContent="$t('modal.adress')"
      value="adress"
      @inputValue="handleInputValue"
    />
    <InputWrapComponent
      :spanContent="$t('modal.city')"
      value="place"
      @inputValue="handleInputValue"
    />
    <InputWrapComponent
      :spanContent="$t('modal.zipcode')"
      value="po_number"
      @inputValue="handleInputValue"
    />
    <InputWrapComponent
      :spanContent="$t('modal.phone')"
      :isPhone="true"
      value="phone"
      @inputValue="handleInputValue"
    />
    <InputWrapComponent
      :spanContent="$t('modal.IMEI')"
      :isImei="true"
      value="imei"
      @inputValue="handleInputValue"
    />
    <div class="upload-ticket-wrap" @click="addFile">
      <span>{{ $t('modal.slipPhoto') }}</span>
      <div>
        <span>
          <img src="../assets/icons/clap.png" alt="clap" />
          <span>{{ $t('modal.uploadPhotoBtn') }}</span>
          <input
            type="file"
            ref="imgInput"
            style="display: none;"
            @change="onFileChange"
          />
        </span>
      </div>
    </div>
    <div class="img-success" v-if="receipt">
      <span>{{ $t('modal.imageSuccess') }}</span>
      <img src="@/assets/tick.png" alt="" />
    </div>
    <div class="terms-wrap">
      <input type="checkbox" @change="checkedTerms = !checkedTerms" />
      <span>
        {{ $t('modal.accept') }}
        <a href="static/redmimkd.pdf" target="__blank">
          {{ $t('modal.terms') }}
        </a>
      </span>
    </div>
    <button
      :disabled="disableBtn"
      :class="{ disabled: checkedTerms }"
      @click="submit"
      @keypress.enter="submit"
      ref="submit"
    >
      {{ $t('modal.submitBtn') }}
    </button>
    <HomeModalToast v-if="submitError" :errorMsg="errorMsg" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import api from '@/api'
import InputWrapComponent from '../components/InputWrapComponent.vue'
import HomeModalToast from './HomeModalToastError.vue'
import convert from 'image-file-resize'
Vue.use(VueReCaptcha, { siteKey: '6Ld-LNUbAAAAAIuYccyHMoTRZ4L6WMPsGJYDAaQu' })

export default {
  components: {
    InputWrapComponent,
    HomeModalToast,
  },
  data() {
    return {
      selected: true,
      retailers: [],
      checkedTerms: true,
      name: '',
      lastname: '',
      address: '',
      place: '',
      po_number: '',
      email: '',
      phone: '',
      ret_id: '',
      imei: '',
      receipt: null,
      submitError: false,
      errorMsg: '',
      currentModels: [],
      selectedModelId: '',
      compressorSettings: {
        toWidth: 800,
        toHeight: 600,
      },
      gifts: [],
      gft_id: null,
      giftImg: null,
    }
  },
  computed: {
    disableBtn() {
      return this.checkedTerms
    },
    currentModelsLength() {
      return this.currentModels.length == 1
    },
  },
  methods: {
    ...mapActions(['setGifts']),
    async getPhoneModels() {
      try {
        const res = await api.getPhoneModels()
        this.currentModels = res.data.data
        if (this.currentModels.length == 1) {
          this.selectedModelId = this.currentModels[0].pro_id
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getRetailers() {
      try {
        const res = await api.getRetailters()
        this.retailers = res.data.data
      } catch (error) {
        console.log(error)
      }
    },
    async submit() {
      /* eslint no-useless-escape: 0 */
      /* eslint brace-style: 0 */
      /* eslint padded-blocks: 0 */
      const mailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const isMail = mailRegex.test(this.email)
      if (
        !this.name ||
        !this.lastname ||
        !this.adress ||
        !this.place ||
        !this.po_number ||
        !this.phone ||
        !this.imei ||
        !this.receipt ||
        !this.ret_id ||
        !this.selectedModelId
      ) {
        this.errorMsg = this.$t('homeModalToastError.fillAll')
        this.submitError = true
        return
      }
      if (!isMail) {
        this.errorMsg = this.$t('homeModalToastError.invalidEmail')
        this.submitError = true
        return
      }
      const token = await this.$recaptcha()
      const formData = new FormData()
      formData.append('name', this.name)
      formData.append('lastname', this.lastname)
      formData.append('email', this.email)
      formData.append('address', this.adress)
      formData.append('place', this.place)
      formData.append('po_number', this.po_number)
      formData.append('phone', this.phone)
      formData.append('imei', this.imei)
      formData.append('receipt', this.receipt)
      formData.append('ret_id', this.ret_id)
      formData.append('gift', this.gft_id)
      formData.append('token', token)
      formData.append('product', this.selectedModelId)
      try {
        const res = await api.submit(formData)
        if (res.status == 200) {
          this.$emit('showSuccess')
        }
      } catch (error) {
        console.log(error.response)
        if (error.response) {
          console.log(error.response.data.form_entity)
          console.log(error.response.data.message)
          if (error.response.data.message === 'IMEI Already claimed') {
            this.errorMsg = this.$t('homeModalToastError.imeiUsed')
          } else if (error.response.data.message === 'Invalid Phone number') {
            this.errorMsg = this.$t('homeModalToastError.phoneInvalid')
          } else if (error.response.data.message === 'Invalid IMEI') {
            this.errorMsg = this.$t('homeModalToastError.imeiInvadlid')
          } else if (error.response.data.message === 'Invalid image') {
            this.errorMsg = this.$t('homeModalToastError.invalidImage')
          } else if (error.response.data.name == 'po_number') {
            this.errorMsg = this.$t('homeModalToastError.zipcodeInvalid')
          } else if (error.response.data.name == 'name') {
            this.errorMsg = this.$t('homeModalToastError.nameInputInvalid')
          } else if (error.response.data.name == 'lastname') {
            this.errorMsg = this.$t('homeModalToastError.lastnameInputInvalid')
          } else if (error.response.data.name == 'place') {
            this.errorMsg = this.$t('homeModalToastError.cityInputInvalid')
          } else if (error.response.data.name == 'phone') {
            this.errorMsg = this.$t('homeModalToastError.phoneInputInvalid')
          }
        }
        this.submitError = true
      }
    },
    addFile() {
      this.$refs.imgInput.click()
    },
    async onFileChange(e) {
      this.receipt = e.target.files[0]
      convert({
        file: e.target.files[0],
        width: 1024,
        height: 768,
        type: 'png',
      })
        .then((resp) => {
          console.log(resp)
          this.receipt = resp
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleInputValue(obj) {
      this.submitError = false
      this.errorMsg = ''
      if (obj.value == 'name') this.name = obj.content
      if (obj.value == 'lastname') this.lastname = obj.content
      if (obj.value == 'email') this.email = obj.content
      if (obj.value == 'adress') this.adress = obj.content
      if (obj.value == 'place') this.place = obj.content
      if (obj.value == 'po_number') this.po_number = obj.content
      if (obj.value == 'phone') this.phone = obj.content
      if (obj.value == 'imei') this.imei = obj.content
    },
    closeModal() {
      this.$emit('closeModal')
    },
    async getGifts() {
      try {
        const res = await api.getGifts()
        this.gifts = res.data.data
        this.giftImg = this.gifts[0].gft_image
        this.gft_id = this.gifts[0].gft_id
        if (this.gifts.length == 1) {
          this.gft_id = this.gifts[0].gft_id
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted() {
    this.$refs.modal.scrollIntoView()
    this.getRetailers()
    this.getPhoneModels()
    this.getGifts()
  },
}
</script>

<style scoped lang="scss">
.home-modal-component {
  position: absolute;
  width: 424px;
  min-height: 1436px;
  background: #ffffff;
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.05);
  left: calc(50% - 212px);
  margin: 0 auto;
  z-index: 1001;
  margin: 60px 0;
  padding: 50px 30px;
  .material-icons {
    position: absolute;
    font-size: 25px;
    left: calc(100% - 30px);
    top: 25px;
    cursor: pointer;
  }
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    font-family: Xiaomi-Regular;
    letter-spacing: -0.01em;
    color: #22242c;
    margin-bottom: 25px;
  }
  .flex-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .flex-wrap-left,
    .flex-wrap-right {
      width: 100%;
      height: 163px;
      border: 1px solid #e4e4e4;
      border-radius: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        color: #4e5565;
        display: block;
        max-width: 115px;
      }
      img {
        display: block;
        width: 44%;
      }
      &.selected {
        background: #ffffff;
        border: 1px solid #ffcca9;
        box-sizing: border-box;
        box-shadow: 0px 0px 11px rgba(255, 105, 0, 0.3);
        border-radius: 11px;
      }
    }
  }
  .selected-item-notification {
    margin-top: 25px;
    margin-bottom: 30px;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      font-family: Xiaomi-Regular;
      color: #888c95;
    }
  }
  .select-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 17px;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      color: #757983;
      display: inline-block;
      margin-bottom: 7px;
    }
    select {
      width: 366px;
      height: 45px;
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0 16px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #5f646f;
      &:focus {
        outline: none;
      }
    }
  }
  .upload-ticket-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 17px;
    cursor: pointer;
    & > span {
      margin-bottom: 7px;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      color: #757983;
      display: inline-block;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 366px;
      height: 45px;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      color: #8c8f97;
      background: #efefef;
    }
  }
  .img-success {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 14px;
    img {
      display: block;
      width: 20px;
      margin-left: 10px;
    }
  }
  .terms-wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    input {
      border: 1px solid #a6abae;
      box-sizing: border-box;
      border-radius: 6px;
      display: block;
    }
    span {
      font-size: 14px;
      a {
        color: #ff6900;
      }
    }
  }
  button {
    width: 366px;
    height: 51px;
    background: #ff6900;
    border: none;
    outline: none;
    margin-top: 60px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    &.disabled {
      background: #f79048;
    }
  }
}
@media only screen and (max-width: 430px) {
  .home-modal-component {
    left: 0;
    width: 100%;
    height: unset;
    .flex-wrap {
      flex-direction: column;
      .flex-wrap-left {
        margin-bottom: 10px;
      }
    }
    .select-wrap {
      select {
        width: 100%;
      }
    }
    .upload-ticket-wrap {
      div {
        width: 100%;
      }
    }
    .terms-wrap {
      text-align: center;
    }
    button {
      width: 100%;
    }
  }
}
</style>
