<template>
  <div class="imei-modal" ref="imei">
    <span class="material-icons" @click="closeModal">close</span>
    <span>
      {{ $t('imeiInfo.imeiExplained') }}
    </span>
    <ul>
      <li>
        {{ $t('imeiInfo.imeiStep1') }}
      </li>
      <li>{{ $t('imeiInfo.imeiStep2') }}</li>
      <li>
        {{ $t('imeiInfo.imeiStep3') }}
      </li>
    </ul>
    <button @click="closeModal">{{ $t('imeiInfo.okBtn') }}</button>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$refs.imei.scrollIntoView({ block: 'center' })
  },
  methods: {
    closeModal() {
      this.$emit('closeIMEIModal')
    },
  },
}
</script>

<style scoped lang="scss">
.imei-modal {
  padding: 40px;
  z-index: 999;
  position: absolute;
  width: 400px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: rgb(71, 79, 83);
  font-weight: bold;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(3 6 7 / 5%) 0px 5px 11px;
  span {
    display: inline-block;
    margin: 20px 0;
  }
  ul {
    li {
      margin: 10px 0;
    }
  }
  .material-icons {
    position: absolute;
    left: calc(100% - 40px);
    top: 0;
    cursor: pointer;
  }
  button {
    display: block;
    background-color: rgb(193, 108, 48);
    color: rgb(239, 236, 233);
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: Xiaomi-Regular;
    border: none;
    outline: none;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    margin: 20px auto 0 auto;
  }
}

@media only screen and (max-width: 400px) {
  .imei-modal {
    width: 100%;
  }
}
</style>
