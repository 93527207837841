<template>
  <div class="home" :class="{ 'modal-opened': showModal }">
    <div
      class="absolute-wrapper"
      v-if="showModal || showSuccessToast || showIMEI"
    ></div>
    <IMEImodal v-if="showIMEI" @closeIMEIModal="handlecloseIMEIModal" />
    <HomeModalComponent
      v-if="showModal"
      @closeModal="handleCloseModal"
      @showSuccess="handleShowSuccess"
    />
    <HomeModalToastSuccess
      v-if="showSuccessToast"
      @closeModal="handleCloseSuccessModal"
    />
    <div class="wrapper">
      <img class="logo" src="../assets/xiaomi_logo.png" alt="logo" />
      <div class="flex-wrap">
        <div class="left">
          <img class="watch" src="../assets/watch_mkd.png" alt="" />
          <img class="phones" src="../assets/redmi11.png" alt="" />
        </div>
        <div class="right">
          <div class="right-top-div">
            <p>
              {{ $t('home.youWereFast') }}
            </p>
            <p>
              <b>{{ $t('home.reactQuickly') }}</b>
            </p>
          </div>
          <div class="right-bottom-div">
            <p>
              <b>{{ $t('home.redmi') }}</b>
              <span>
                {{ $t('home.phoneModel') }}
              </span>
            </p>
            <p class="phone-details">
              {{ $t('home.phoneDetails') }}
            </p>
          </div>
          <div>
            <div class="button-container">
              <button
                class="choose-gift"
                @click="showModal = !showModal"
                :disabled="siteEnabled"
              >
                {{ $t('home.applyBtn') }}
              </button>
              <button
                class="rules"
                @click="handleShowIMEI"
                :disabled="siteEnabled"
              >
                {{ $t('home.IMEIinfoBtn') }}
              </button>
            </div>
            <div class="support-mail">
              <span>{{ $t('home.supportEmail') }}</span>
              <span>{{ $t('home.mailItself') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-wrap-responsive">
        <div class="top">
          <div>
            <p>
              {{ $t('home.youWereFast') }}
            </p>
            <p>
              <!-- <b>{{ $t('home.redmi') }}</b>
              <span>
                {{ $t('home.phoneModel') }}
              </span> -->
              {{$t('home.reactQuickly')}}
            </p>
          </div>
          <img class="phones" src="../assets/redmi11.png" alt="" />
        </div>
        <div class="bottom">
          <div>
            <p>
              {{ $t('home.timeToClaim') }}
            </p>
            <p>
              <b>{{ $t('home.redmi') }}</b>
              <span>
                {{ $t('home.watchModel') }}
              </span>
            </p>
          </div>
          <img class="watch" src="../assets/watch_mkd.png" alt="" />
        </div>
        <div class="button-section">
          <div class="button-container">
            <button
              class="choose-gift"
              @click="showModal = !showModal"
              :disabled="siteEnabled"
            >
              {{ $t('home.applyBtn') }}
            </button>
            <button
              class="rules"
              @click="handleShowIMEI"
              :disabled="siteEnabled"
            >
              {{ $t('home.IMEIinfoBtn') }}
            </button>
          </div>
          <div class="support-mail">
            <span>{{ $t('home.supportEmail') }}</span>
            <span>{{ $t('home.mailItself') }}</span>
          </div>
        </div>
        <span class="active-by">
          {{ $t('home.date') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import HomeModalComponent from '../components/HomeModalComponent.vue'
import HomeModalToastSuccess from '../components/HomeModalToastSuccess.vue'
import IMEImodal from '../components/IMEImodal.vue'
export default {
  components: {
    HomeModalComponent,
    HomeModalToastSuccess,
    IMEImodal,
  },
  data() {
    return {
      showModal: false,
      showSuccessToast: false,
      showIMEI: false,
      siteEnabled: false,
    }
  },
  computed: {
    disabled() {
      return this.siteEnabled
    },
  },
  mounted() {
    this.checkDeadline()
  },
  methods: {
    handleCloseModal() {
      this.showModal = false
    },
    handleCloseSuccessModal() {
      this.showSuccessToast = false
    },
    handleShowSuccess() {
      this.showModal = false
      this.showSuccessToast = true
    },
    handleShowIMEI() {
      this.showIMEI = true
    },
    handlecloseIMEIModal() {
      this.showIMEI = false
    },
    async checkDeadline() {
      try {
        const res = await api.checkDeadline()
        this.siteEnabled = res.data.site_enabled
        console.log(this.siteEnabled)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.home {
  position: relative;
  background: #e7eaf5;
  padding: 50px 75px 0 0;
  &.modal-opened {
    padding: 0;
  }
  .absolute-wrapper {
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: 0.9;
    z-index: 10;
    position: absolute;
  }
  .wrapper {
    max-width: 1920px;
    margin: 0 auto;
    // padding: 100px 80px 0 80px;
    background-repeat: no-repeat;
    .flex-wrap {
      display: flex;
      .left {
        max-width: 50%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        position: relative;
        flex-direction: column;
        margin: 0 120px 0 0;
        .phones {
          display: block;
          width: 1000px;
        }
        .watch {
          width: 300px;
          align-self: flex-end;
          // margin: 0 300px 0 0;
        }
      }
      .right {
        z-index: 3;
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
        .right-top-div {
          p {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 40px;
            line-height: 58px;
            letter-spacing: 0.03em;
            color: #000000;
            b {
              margin: 5px 0;
              font-family: Roboto;
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 32px;
              letter-spacing: 0.03em;
              color: #000000;
              width: 450px;
              display: block;
            }
          }
        }
        .right-bottom-div {
          p {
            font-family: Roboto;
            font-style: normal;
            font-size: 47px;
            line-height: 74px;
            letter-spacing: 0.03em;
            color: #000000;
            b {
              font-family: Roboto;
              font-style: normal;
              font-weight: bold;
              font-size: 47px;
              line-height: 74px;
              letter-spacing: 0.03em;
              color: #000000;
            }
          }
          .phone-details {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 25px;
            line-height: 32px;
            letter-spacing: 0.03em;
            color: #000000;
          }
        }
      }
    }
    .logo {
      display: block;
      width: 80px;
      margin: 0 0 15px calc(100% - 88px);
    }
    .button-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      margin: 0 auto;
      button {
        width: 201px;
        height: 61px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-family: Xiaomi-Regular;
        border: none;
        outline: none;
        cursor: pointer;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        position: relative;
        z-index: 9;
      }
      .choose-gift {
        background: #ff6900;
        color: #ffffff;
      }
      .rules {
        background: #fff;
        color: #ff6900;
      }
    }
    .flex-wrap-responsive {
      display: none;
    }
  }
  .support-mail {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      display: inline-block;
      text-align: center;
    }
    span:nth-of-type(2) {
      color: #ff6900;
    }
  }
}
@media only screen and (max-width: 1556px) {
  .home {
    .wrapper {
      .flex-wrap {
        .left {
          .phones {
            width: 900px;
            // display: none;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1315px) {
  .home {
    .wrapper {
      .flex-wrap {
        .left {
          .phones {
            width: 800px;
            // display: none;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .home {
    padding: 10px 0 0 0;
    .wrapper {
      .flex-wrap {
        display: none;
      }
      .flex-wrap-responsive {
        display: block;
        .top {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .phones {
            display: block;
            width: 800px;
            align-self: flex-start;
          }
          p {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 40px;
            line-height: 74px;
            letter-spacing: 0.03em;
            color: #000000;
            text-align: center;
            margin: 0 0 50px 0;
          }
        }
        .bottom {
          margin: 30px 0 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .watch {
          }
          p {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 40px;
            line-height: 60px;
            letter-spacing: 0.03em;
            color: #000000;
            text-align: center;
          }
        }
        .button-section {
          .button-container {
            justify-content: space-around;
            button {
              width: 250px;
            }
          }
        }
        .active-by {
          margin: 20px auto 0 auto;
          display: block;
          text-align: center;
        }
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .home {
    .wrapper {
      .flex-wrap-responsive {
        .top {
          .phones {
            width: unset;
            max-width: 100%;
          }
        }
        .bottom {
          .watch {
            width: unset;
            max-width: 70%;
            display: block;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .home {
    .wrapper {
      .flex-wrap-responsive {
        .top,
        .bottom {
          p {
            font-size: 30px;
          }
        }
      }
      .button-section {
        .button-container {
          button {
            width: 200px !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .home {
    .wrapper {
      .flex-wrap-responsive {
        .top,
        .bottom {
          p {
            font-size: 30px;
          }
        }
      }
      .button-section {
        .button-container {
          button {
            width: 150px !important;
          }
        }
      }
    }
  }
}
</style>
