import { instance as axios, baseURL } from './config.js'

export default {
  getRetailters: () =>
    axios({
      url: `/retailers`,
      method: 'GET',
    }),
  checkSession: (params) =>
    axios({
      url: `/check-session?sid=${params.sid}`,
      method: 'GET',
      params: params,
    }),
  login: (params) =>
    axios({
      url: '/login',
      method: 'POST',
      data: params,
    }),
  logout: (params) =>
    axios({
      url: '/logout',
      method: 'POST',
      data: params,
    }),
  submit: (params) =>
    axios({
      url: '/submit',
      method: 'POST',
      data: params,
      headers: {
        'content-type': 'multipart/form-data',
      },
    }),
  getSubmissions: (params) =>
    axios({
      url: '/submissions',
      method: 'GET',
      params: params,
    }),
  searchSubmissions: (params) =>
    axios({
      url: `/submissions`,
      method: 'GET',
      params: params,
    }),
  getImg: (params) =>
    axios({
      url: `/submission/${params.frm_id}/receipt`,
      method: 'GET',
      params: params,
    }),
  submitSingleTicket: (params) =>
    axios({
      url: `/submission/${params.id}`,
      method: 'PUT',
      params: params,
    }),
  getCsvData: (params) =>
    axios({
      url: '/excel_export',
      method: 'GET',
      params: params,
    }),
  getPhoneModels: () =>
    axios({
      url: '/products',
      method: 'GET',
    }),
  getGifts: () =>
    axios({
      url: '/gifts',
      method: 'GET',
    }),
  checkDeadline: () =>
    axios({
      url: '/check_deadline',
      method: 'GET',
    }),
}
